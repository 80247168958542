<template>
  <section
    ref="root"
    v-kog-mathjax
    class="KogTable-section"
  >
    <div
      v-if="isTitleSectionVisible"
      class="KogTable-titleBox padd-right-xxs padd-left-s padd-top-s padd-bottom-xxs"
    >
      <h1
        v-if="title"
        class="heading-s padd-bottom-xs"
      >
        {{ title }}
      </h1>
      <slot name="subtitle" />
    </div>
    <div class="KogTable-wrapper">
      <div
        ref="kogTableScrollContainer"
        class="KogTable-wrapper--inner"
        :class="{
          'KogTable-wrapper--inner--verticalScroll': isVerticalScrollable,
        }"
      >
        <table
          ref="kogTableTable"
          class="KogTable-table"
          :class="{
            'KogTable-table--stickyHeader': isHeaderSticky,
            'KogTable-table--withVerticalCellPadding': isVerticalCellPaddingEnabled,
            'KogTable--noBorders': !hasBorders,
          }"
        >
          <colgroup v-if="isColgroupPresent">
            <slot name="colgroup" />
          </colgroup>
          <thead v-if="isHeaderPresent">
            <slot name="header" />
          </thead>
          <tbody
            v-if="!isLoadingTableData"
            class="js-KogTable-body"
          >
            <slot name="body" />
          </tbody>
        </table>
      </div>
      <div
        class="KogTable-leftScrollIndicator"
        :class="{
          'KogTable-leftScrollIndicator--showShadow': isShowingShadowLeft,
        }"
        :style="{
          left: `${leftScrollShadowPosition - 10}px`,
        }"
      />
      <div
        v-if="isShowingShadowLeft"
        aria-hidden="true"
        class="KogTable-leftScrollButtonContainer flexContainer flexContainer-column flexContainer-center flexContainer-alignStart"
        :style="{
          left: `${leftScrollShadowPosition + 8}px`,
        }"
      >
        <kog-round-button
          aria-label="Scroll left"
          icon-class="fa-chevron-left"
          @click="scrollLeft"
        />
      </div>
      <div
        v-if="isShowingShadowRight"
        aria-hidden="true"
        class="KogTable-rightScrollButtonContainer flexContainer flexContainer-column flexContainer-center flexContainer-alignEnd"
        :style="{
          right: `${rightScrollShadowPosition + 8}px`,
        }"
      >
        <kog-round-button
          aria-label="Scroll right"
          icon-class="fa-chevron-right"
          @click="scrollRight"
        />
      </div>
      <div
        class="KogTable-rightScrollIndicator"
        :class="{
          'KogTable-rightScrollIndicator--showShadow': isShowingShadowRight,
        }"
        :style="{
          right: `${rightScrollShadowPosition - 10}px`,
        }"
      />
    </div>
    <kog-loader
      v-if="isLoadingTableData"
      :loading="isLoadingTableData"
      :loading-msg="loadingTableDataMessage"
      class="margin-top-m"
    />
    <slot
      v-else
      name="info"
    />
    <kog-table-footer
      v-if="footerText.length > 0 || hasPagination"
      :text="footerText"
    >
      <template #pagination>
        <kog-paginator
          v-if="hasPagination"
          :current-page="paginationCurrentPage"
          :number-of-pages="paginationNumberOfPages"
          :pagination-type="paginationType"
          @change-page="updateCurrentPage"
        />
      </template>
    </kog-table-footer>
  </section>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import { markRaw } from 'vue';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogPaginator from 'sharedApp/components/base/pagination/kog-paginator.vue';
import KogTableFooter from 'sharedApp/components/tables/kog-table-footer.vue';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
const HEADER_CELL_STICKY_CLASS = 'KogTable-headerCell--sticky';
const BODY_CELL_STICKY_CLASS = 'KogTable-bodyCell--sticky';

export default {
  name: 'KogTable',
  components: {
    KogRoundButton,
    KogTableFooter,
    KogPaginator,
    KogLoader,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    footerText: {
      type: String,
      default: '',
    },
    paginationCurrentPage: {
      type: Number,
      default: 0,
    },
    paginationNumberOfPages: {
      type: Number,
      default: 0,
    },
    updateCurrentPage: {
      type: Function,
      default: () => false,
    },
    isLoadingTableData: {
      type: Boolean,
      default: false,
    },
    loadingTableDataMessage: {
      type: String,
      default: 'Loading table data...',
    },
    rowSeparator: {
      type: String,
      default: 'none',
      validator: prop => ['none', 'border'].includes(prop),
    },
    paginationType: {
      type: String,
      default: '',
    },
    /**
     * If true, the table header will stick to the top side of the native `table`'s parent div
     *
     */
    isHeaderSticky: {
      type: Boolean,
      default: false,
    },
    /**
     * The number of columns, counting from the left to the right,
     * that should remain fixed when scrolling
     */
    stickyLeftColumnCount: {
      type: Number,
      default: 0,
    },
    /**
     * The number of columns, counting from the right to the left,
     * that should remain fixed when scrolling
     */
    stickyRightColumnCount: {
      type: Number,
      default: 0,
    },
    /**
     * If `true`, the table will control vertical scrolling itself.
     */
    isVerticalScrollable: {
      type: Boolean,
      default: false,
    },
    /**
     * If `true`, the cells in the table will have top and bottom padding
     */
    isVerticalCellPaddingEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShowingShadowLeft: false,
      isShowingShadowRight: false,
      contentMutationObserver: null,
      rightEdgeIntersectionObserver: null,
      leftEdgeIntersectionObserver: null,
      tableResizeObserver: null,
      leftScrollShadowPosition: 0,
      rightScrollShadowPosition: 0,
      latestTableWidth: null,
    };
  },
  computed: {
    isTitleSectionVisible() {
      const isSubtitleVisible = Boolean(this.$slots.subtitle);
      return this.title || isSubtitleVisible;
    },
    isColgroupPresent() {
      return Boolean(this.$slots.colgroup);
    },
    isHeaderPresent() {
      return Boolean(this.$slots.header);
    },
    hasPagination() {
      return this.paginationNumberOfPages > 1;
    },
    hasStickyLeftColumns() {
      return this.stickyLeftColumnCount > 0;
    },
    hasStickyRightColumns() {
      return this.stickyRightColumnCount > 0;
    },
    hasBorders() {
      return this.rowSeparator === 'border';
    },
  },
  mounted() {
    this.setStickyColumnsStyle();
    this.createTableResizeObserver();
    this.createIntersectionObservers();
    this.createTableContentMutationObserver();
  },
  unmounted() {
    this.cleanupObservers();
  },
  methods: {
    createTableContentMutationObserver() {
      if (!window.MutationObserver) {
        return;
      }

      this.contentMutationObserver = new MutationObserver(this.handleTableBodyContentChanged);
      this.contentMutationObserver.observe(this.$refs.kogTableTable, {
        attributes: false,
        childList: true,
        characterData: false,
        subtree: true,
      });
    },
    createIntersectionObservers() {
      if (!window.IntersectionObserver) {
        return;
      }

      this.createRightEdgeIntersectionObserver();
      this.createLeftEdgeIntersectionObserver();
    },
    createRightEdgeIntersectionObserver() {
      if (typeof this.$refs.root.querySelector !== 'function') {
        return;
      }
      let lastScrollableCell;
      let intersectionMargin = 0;
      const lastHeaderCell = this.$refs.root.querySelector('thead th:last-of-type');

      if (this.hasStickyRightColumns) {
        const totalColumnCount = this.$refs.root.querySelectorAll(`thead>tr th`).length;
        const maxScrollableColumnIndex = totalColumnCount - this.stickyRightColumnCount;

        for (let colIndex = totalColumnCount; colIndex > maxScrollableColumnIndex; colIndex -= 1) {
          const columnHeaderCell = this.$refs.root.querySelector(
            `thead th:nth-of-type(${colIndex})`,
          );
          intersectionMargin += columnHeaderCell.scrollWidth;
        }

        this.rightScrollShadowPosition = intersectionMargin;
        lastScrollableCell = this.$refs.root.querySelector(
          `thead th:nth-of-type(${totalColumnCount - this.stickyRightColumnCount})`,
        );
      } else {
        lastScrollableCell = lastHeaderCell;
      }

      const rightEdgeIntersectionOptions = {
        root: this.$refs.kogTableScrollContainer,
        rootMargin: `0px -${intersectionMargin}px 0px 0px`,
        threshold: [0.9],
      };

      this.rightEdgeIntersectionObserver = new IntersectionObserver(
        this.handleRightEdgeIntersect,
        rightEdgeIntersectionOptions,
      );
      this.rightEdgeIntersectionObserver.observe(lastScrollableCell);
    },
    createLeftEdgeIntersectionObserver() {
      if (typeof this.$refs.root.querySelector !== 'function') {
        return;
      }
      let intersectionMargin = 0;
      let firstScrollableCell;
      const firstHeaderCell = this.$refs.root.querySelector('thead th:first-of-type');

      if (this.hasStickyLeftColumns) {
        firstScrollableCell = this.$refs.root.querySelector(
          `thead th:nth-of-type(${this.stickyLeftColumnCount + 1})`,
        );
        intersectionMargin = firstScrollableCell.offsetLeft;
        this.leftScrollShadowPosition = intersectionMargin;
      } else {
        firstScrollableCell = firstHeaderCell;
      }

      const leftEdgeIntersectionOptions = {
        root: this.$refs.kogTableScrollContainer,
        rootMargin: `0px 0px 0px -${intersectionMargin}px`,
        threshold: [0.9],
      };

      this.leftEdgeIntersectionObserver = new IntersectionObserver(
        this.handleLeftEdgeIntersect,
        leftEdgeIntersectionOptions,
      );

      this.leftEdgeIntersectionObserver.observe(firstScrollableCell);
    },
    createTableResizeObserver() {
      const table = this.$refs.kogTableTable;
      this.tableResizeObserver = markRaw(new ResizeObserver(this.handleTableSizeChanged));
      this.tableResizeObserver.observe(table);
    },
    handleRightEdgeIntersect(entries) {
      if (entries[0].intersectionRatio >= 0.9) {
        this.isShowingShadowRight = false;
      } else {
        this.isShowingShadowRight = true;
      }
    },
    handleLeftEdgeIntersect(entries) {
      if (entries[0].intersectionRatio >= 0.9) {
        this.isShowingShadowLeft = false;
      } else {
        this.isShowingShadowLeft = true;
      }
    },
    handleTableBodyContentChanged() {
      this.setStickyColumnsStyle();
      this.cleanupIntersectionObservers();
      this.createIntersectionObservers();
    },
    handleTableSizeChanged(resizeObserverList) {
      this.setStickyColumnsStyle();
      const resizeObserverEntry = resizeObserverList[0];
      const currentTableWidth = resizeObserverEntry.contentRect.width;
      if (currentTableWidth !== this.latestTableWidth) {
        this.latestTableWidth = currentTableWidth;
        this.cleanupIntersectionObservers();
        this.createIntersectionObservers();
      }
    },
    clearExistingStickyColumnClasses() {
      if (typeof this.$refs.root.querySelectorAll !== 'function') {
        return;
      }
      const stickyHeaderCells = this.$refs.root.querySelectorAll(`.${HEADER_CELL_STICKY_CLASS}`);
      stickyHeaderCells.forEach(cell => {
        cell.classList.remove(HEADER_CELL_STICKY_CLASS);
      });

      const stickyBodyCells = this.$refs.root.querySelectorAll(`.${BODY_CELL_STICKY_CLASS}`);
      stickyBodyCells.forEach(cell => {
        cell.classList.remove(BODY_CELL_STICKY_CLASS);
      });
    },
    setStickyColumnsStyle() {
      this.clearExistingStickyColumnClasses();
      this.setStickyLeftColumnsStyle();
      this.setStickyRightColumnsStyle();
    },
    setStickyLeftColumnsStyle() {
      if (!this.hasStickyLeftColumns || typeof this.$refs.root.querySelector !== 'function') {
        return;
      }

      let columnOffsetLeft = 0;

      for (let colIndex = 1; colIndex <= this.stickyLeftColumnCount; colIndex += 1) {
        const columnHeaderCell = this.$refs.root.querySelector(`thead th:nth-of-type(${colIndex})`);
        columnHeaderCell.classList.add(HEADER_CELL_STICKY_CLASS);
        columnHeaderCell.style.left = `${columnOffsetLeft}px`;

        const columnBodyCellList = this.$refs.root.querySelectorAll(
          `tbody tr td:nth-of-type(${colIndex})`,
        );
        // eslint-disable-next-line no-loop-func
        columnBodyCellList.forEach(columnBodyCell => {
          columnBodyCell.classList.add(BODY_CELL_STICKY_CLASS);
          // eslint-disable-next-line no-param-reassign
          columnBodyCell.style.left = `${columnOffsetLeft}px`;
        });

        columnOffsetLeft += columnHeaderCell.scrollWidth;
      }
    },
    setStickyRightColumnsStyle() {
      if (!this.hasStickyRightColumns || typeof this.$refs.root.querySelectorAll !== 'function') {
        return;
      }

      let columnOffsetRight = 0;
      const totalColumnCount = this.$refs.root.querySelectorAll(`thead>tr th`).length;

      for (
        let colIndex = totalColumnCount;
        colIndex > totalColumnCount - this.stickyRightColumnCount;
        colIndex -= 1
      ) {
        const columnHeaderCell = this.$refs.root.querySelector(`thead th:nth-of-type(${colIndex})`);
        columnHeaderCell.classList.add(HEADER_CELL_STICKY_CLASS);
        columnHeaderCell.style.right = `${columnOffsetRight}px`;

        const columnBodyCellList = this.$refs.root.querySelectorAll(
          `tbody tr td:nth-of-type(${colIndex})`,
        );
        // eslint-disable-next-line no-loop-func
        columnBodyCellList.forEach(columnBodyCell => {
          columnBodyCell.classList.add(BODY_CELL_STICKY_CLASS);
          // eslint-disable-next-line no-param-reassign
          columnBodyCell.style.right = `${columnOffsetRight}px`;
        });

        columnOffsetRight += columnHeaderCell.scrollWidth;
      }
    },
    scrollLeft() {
      const scrollContainer = this.$refs.root.querySelector('.KogTable-wrapper--inner');
      const headerCells = Array.from(this.$refs.root.querySelectorAll('thead th'));
      const currentScroll = scrollContainer.scrollLeft;
      let positionToScroll = 0;

      headerCells.splice(0, this.stickyLeftColumnCount);
      headerCells.every(cell => {
        if (
          positionToScroll < currentScroll &&
          positionToScroll + cell.clientWidth >= currentScroll
        ) {
          return false;
        }

        positionToScroll += cell.clientWidth;
        return true;
      });

      scrollContainer.scrollTo({
        left: positionToScroll,
        behavior: 'smooth',
      });
    },
    scrollRight() {
      const scrollContainer = this.$refs.root.querySelector('.KogTable-wrapper--inner');
      const scrollContainerWidth = scrollContainer.clientWidth;
      const headerCells = Array.from(this.$refs.root.querySelectorAll('thead th'));
      const currentScroll = scrollContainer.scrollLeft;
      let cellToScrollPosition = 0;

      headerCells.every(cell => {
        if (
          cellToScrollPosition <= scrollContainerWidth + currentScroll &&
          cellToScrollPosition + cell.clientWidth > scrollContainerWidth + currentScroll
        ) {
          cellToScrollPosition += cell.clientWidth;
          return false;
        }

        cellToScrollPosition += cell.clientWidth;
        return true;
      });

      const positionToScroll = cellToScrollPosition - scrollContainerWidth;

      scrollContainer.scrollTo({
        left: positionToScroll,
        behavior: 'smooth',
      });
    },
    cleanupObservers() {
      this.cleanupTableResizeObserver();
      this.cleanupContentMutationObserver();
      this.cleanupIntersectionObservers();
    },
    cleanupIntersectionObservers() {
      this.cleanupRightEdgeIntersectionObserver();
      this.cleanupLeftEdgeIntersectionObserver();
    },
    cleanupRightEdgeIntersectionObserver() {
      if (this.rightEdgeIntersectionObserver) {
        this.rightEdgeIntersectionObserver.disconnect();
      }
    },
    cleanupLeftEdgeIntersectionObserver() {
      if (this.leftEdgeIntersectionObserver) {
        this.leftEdgeIntersectionObserver.disconnect();
      }
    },
    cleanupContentMutationObserver() {
      if (this.contentMutationObserver) {
        this.contentMutationObserver.disconnect();
      }
    },
    cleanupTableResizeObserver() {
      if (this.tableResizeObserver) {
        this.tableResizeObserver.disconnect();
      }
    },
  },
};
</script>

<style scoped>
.KogTable-section,
.KogTable-wrapper {
  display: flex;
  flex-direction: column;
}

.KogTable-titleBox {
  background-color: var(--kog-table-title-background-color);
}

.KogTable-wrapper {
  position: relative;
  overflow: hidden;
}

.KogTable-wrapper--inner {
  overflow-x: auto;
}

.KogTable-wrapper--inner--verticalScroll {
  overflow-y: auto;
}

.KogTable-rightScrollIndicator {
  position: absolute;
  z-index: 1;
  top: 0;

  width: 10px;
  height: 100%;

  transition: box-shadow 100ms ease;
}

.KogTable-leftScrollIndicator {
  position: absolute;
  z-index: 1;
  top: 0;
  left: -10px;

  width: 10px;
  height: 100%;

  transition: box-shadow 100ms ease;
}

.KogTable-leftScrollButtonContainer {
  position: absolute;

  overflow: visible;

  max-width: 0;
  height: 100%;
  padding-top: 48px;
}

.KogTable-rightScrollButtonContainer {
  position: absolute;

  overflow: visible;

  max-width: 0;
  height: 100%;
  padding-top: 48px;
}

.KogTable-rightScrollIndicator--showShadow {
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.2);
}

.KogTable-leftScrollIndicator--showShadow {
  box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.2);
}

.KogTable--noBorders {
  border-collapse: separate;
}

table {
  table-layout: auto;
  width: 100%;
}

table :deep(td) {
  padding-top: 0;
  padding-bottom: 0;
}

.KogTable-table--withVerticalCellPadding :deep(td),
table :deep(th) {
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

table :deep(thead th) {
  background-color: var(--kog-table-header-background-color);
}

.KogTable-table--stickyHeader :deep(thead th) {
  position: sticky;
  z-index: 1;
  top: 0;
}

.KogTable-table :deep(.KogTable-headerCell--sticky) {
  position: sticky;
  z-index: 3;
}

.KogTable-table :deep(.KogTable-bodyCell--sticky) {
  position: sticky;
  z-index: 2;
  background-color: var(--kog-table-sticky-cell-background-color);
}
</style>
