<!--
    Confirm modal component.

    How to use:
      this.$modal(KogConfirmModal, {
        title: 'Delete annotation',
        text: 'Do you want to delete the annotation?',
        buttonTitle: 'Delete', // optional, defaults to 'Delete'
        buttonClass: 'KogButtonLegacy--danger', // optional, defaults to KogButtonLegacy--danger
        onConfirm: () => {
          // do stuff
        },
        onCancel: () => {
          // onCancel is optional - use if you want to do something more
          // than just closing the modal when the user presses cancel
        },
      });
-->

<template>
  <kog-modal :title="title">
    <template #modalBody>
      <p
        v-for="line in textLines"
        :key="line"
      >
        {{ line }}
      </p>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <kog-button
          label="Cancel"
          :button-style="cancelButtonStyle"
          :disabled="isBusy"
          @click="onCancelHandler"
        />
        <kog-button
          :label="showPrimaryButtonTitle"
          :button-style="generatedConfirmButtonStyle"
          :is-loading="isBusy"
          @click="onConfirmHandler"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';

import KogModal from './kog-modal.vue';

export default {
  name: 'KogConfirmModal',
  components: {
    KogModal,
    KogButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    buttonTitle: {
      type: String,
      default: null,
    },
    cancelButtonStyle: {
      type: String,
      default: undefined,
    },
    confirmButtonStyle: {
      type: String,
      default: undefined,
    },
    buttonClass: {
      type: String,
      default: 'KogButtonLegacy--danger',
    },
    onConfirm: {
      type: Function,
      required: true,
    },
    onCancel: {
      type: Function,
      default: null,
    },
    waitForAction: {
      type: Boolean,
      default: false,
    },
    // this property is set by the modal container
    closeModal: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
    };
  },
  computed: {
    generatedConfirmButtonStyle() {
      if (this.confirmButtonStyle) {
        return this.confirmButtonStyle;
      }

      const css2button = {
        'KogButtonLegacy--danger': 'danger',
        'KogButtonLegacy--primary': 'primary',
        'KogButtonLegacy--link': 'basic',
      };
      return css2button[this.buttonClass];
    },
    showPrimaryButtonTitle() {
      if (this.buttonTitle) {
        return this.buttonTitle;
      }
      return 'Delete';
    },
    textLines() {
      // split on consecutive blank lines
      return this.text.split(/\n[ \t]*\n/g);
    },
  },
  methods: {
    async onConfirmHandler() {
      if (this.waitForAction) {
        this.isBusy = true;
        await this.onConfirm();
        this.isBusy = false;
        this.closeModal();
      } else {
        this.onConfirm();
        this.closeModal();
      }
    },
    onCancelHandler() {
      if (this.onCancel) {
        this.onCancel();
      }
      this.closeModal();
    },
  },
};
</script>
