<template>
  <kog-item-list
    :class="{ 'flexContainer-center': !isLeftAligned }"
    class="KogTabs flexContainer flexContainer-wrap"
    role="tablist"
  >
    <kog-tab
      v-for="tab in primaryTabs"
      v-bind="tab.customProps"
      :key="tab.name"
      v-kog-new-dot="tab.newDot"
      :name="tab.name"
      :label="tab.label"
      :heading-size="headingSize"
      :is-tab-selected="isSelected(tab)"
      :has-transparent-background="hasTransparentBackground"
      :notifications-count="tab.notificationsCount"
      :notifications-tooltip-text="tab.notificationsTooltipText"
      :icon-size="iconSize"
      :icon-class="tab.iconClass"
      @click="onTabClick(tab)"
    />
    <span
      v-if="secondaryTabs.length > 0"
      class="flexChild-canGrow"
    />
    <kog-tab
      v-for="tab in secondaryTabs"
      v-bind="tab.customProps"
      :key="tab.name"
      v-kog-new-dot="tab.newDot"
      :name="tab.name"
      :label="tab.label"
      :heading-size="headingSize"
      :is-tab-selected="isSelected(tab)"
      :has-transparent-background="hasTransparentBackground"
      :notifications-count="tab.notificationsCount"
      :notifications-tooltip-text="tab.notificationsTooltipText"
      :icon-size="iconSize"
      :icon-class="tab.iconClass"
      @click="onTabClick(tab)"
    />
  </kog-item-list>
</template>

<script>
import KogTab from 'sharedApp/components/base/tabs/kog-tab.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';

export default {
  name: 'KogTabs',
  components: {
    KogTab,
    KogItemList,
  },
  props: {
    /**
     * The `name` of the currently active tab.
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * The list of tabs to display<br>
     * Structure of a **tab** item:<br>
     * - `name: String`<br>
     * - `label: String`<br>
     * - `notificationsCount: Number` (optional)<br>
     * - `notificationsTooltipText: String` (optional)<br>
     * - `newDot: Object` (optional)<br>
     * - `customProps: Object` (optional)<br>
     */
    tabs: {
      type: Array,
      required: true,
    },
    /**
     * Set to true to render the tabs with a transparent background.
     */
    hasTransparentBackground: {
      type: Boolean,
      default: false,
    },
    /**
     * The font style used to render the tabs.
     */
    headingSize: {
      type: String,
      default: 'xxs',
    },
    /**
     * The icon size used to render the tabs when any icons are present.
     */
    iconSize: {
      type: String,
      default: 's',
    },
    /**
     * Set to true if you want left-aligned tabs.
     * By default, tabs are centered.
     */
    isLeftAligned: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value'],
  computed: {
    primaryTabs() {
      return this.tabs.filter(tab => !tab.inSecondaryTabGroup);
    },
    secondaryTabs() {
      return this.tabs.filter(tab => tab.inSecondaryTabGroup);
    },
  },
  methods: {
    onTabClick(tab) {
      this.$emit('update:value', tab.name);
      this.$emit('input', tab.name);
      if (tab.onTabSelected) {
        tab.onTabSelected();
      }
    },
    isSelected(tab) {
      return tab.name === this.value;
    },
  },
};
</script>

<style scoped>
.KogTabs {
  max-height: 50px;
  padding: 0;
  border-bottom: 1px solid var(--kog-tabs-border-bottom-color);
}
</style>
