<template>
  <tr
    ref="root"
    class="KogTableHeader padd-top-xs padd-bottom-xs"
    :class="{
      'KogTableHeader--disabled': isDisabled,
    }"
  >
    <slot />
  </tr>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix
export default {
  name: 'KogTableHeader',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.KogTableHeader {
  height: 48px;
}

.KogTableHeader--disabled {
  color: var(--kog-table-row-disabled-color);
}
</style>
