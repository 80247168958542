<template>
  <component
    :is="rowOrHeaderCell"
    :class="{
      'text-right': isLabelOnLeft && !isLabelHidden,
      'text-nowrap': !isMultiLine,
    }"
    class="KogTableCellCheckbox KogTableCellCheckbox--centered padd-left-xs padd-right-xs"
  >
    <kog-checkbox
      :label="label"
      :tooltip-text="tooltipText"
      :aria-label="ariaLabel"
      :is-label-hidden="isLabelHidden"
      :is-checked="isChecked"
      :is-disabled="isDisabled"
      :is-label-on-left="isLabelOnLeft"
      @toggled="toggled"
    />
  </component>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import KogCheckbox from 'sharedApp/components/base/checkbox/kog-checkbox.vue';

export default {
  name: 'KogTableCellCheckbox',
  components: {
    KogCheckbox,
  },
  props: {
    /**
     * Optional message to be read out by assistive screen reader technologies.
     */
    ariaLabel: {
      type: String,
      default: '',
    },
    /**
     * The checkbox can be used in a row header.
     * It will be a th instead of a td.
     */
    isHeaderCell: {
      type: Boolean,
      default: false,
    },
    /**
     * Its value can be *true*, *false* or *\'mixed\'*'
     */
    isChecked: {
      required: true,
      validator: prop => typeof prop === 'boolean' || prop === 'mixed',
    },
    /**
     * Specifies content of optional tooltip that shows on checkbox hover.
     * No tooltip shown if this property is not provided.
     */
    tooltipText: {
      type: String,
      default: '',
    },
    /**
     * If true, the checkbox is not interactable
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Hides the label (the label will still be used as for aria-label)
     */
    isLabelHidden: {
      type: Boolean,
      default: true,
    },
    /**
     * Set this to true if you want the label to appear on the left side of the checkbox.
     */
    isLabelOnLeft: {
      type: Boolean,
      default: false,
    },
    /**
     * The label should always be specified, for accessibility purposes.
     * If you don't want it to be visible, use isLabelHidden: true
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Allow to display the text on multiple lines
     */
    isMultiLine: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['check', 'uncheck'],
  computed: {
    rowOrHeaderCell() {
      return this.isHeaderCell ? 'th' : 'td';
    },
  },
  methods: {
    toggled(isChecked) {
      if (isChecked) {
        /**
         * Emits check event after being toggled to be checked
         * @event check
         * @type {empty}
         */
        return this.$emit('check');
      }
      /**
       * Emits uncheck event after being toggled to be unchecked
       * @event uncheck
       * @type {empty}
       */
      return this.$emit('uncheck');
    },
  },
};
</script>

<style scoped>
.KogTableCellCheckbox {
  position: relative;
  min-width: 48px;
  line-height: 24px;
}

.KogTableCellCheckbox--centered {
  vertical-align: middle;
}
</style>
