<template>
  <div
    class="KogTab padd-top-xs padd-bottom-xs padd-left-m padd-right-m cursorPointer muted"
    :tabindex="isTabSelected ? 0 : -1"
    :class="[
      `heading-${headingSize}`,
      {
        'KogTab--transparent ': hasTransparentBackground,
        'KogTab--active': isTabSelected,
        'KogTab--inactive': !isTabSelected,
        'padd-right-l': showNotification,
      },
    ]"
    role="tab"
    :aria-selected="isTabSelected ? 'true' : 'false'"
    @keydown.enter="onClick"
    @keydown.prevent.space="onClick"
    @click="onClick"
  >
    <kog-icon
      v-if="iconClass"
      class="margin-right-xxs"
      :icon-class="iconClass"
      :size="iconSize"
      fa-style="regular"
      theme="custom"
    />
    {{ label }}
    <kog-notification
      v-if="showNotification"
      :offset-top="2"
      :offset-left="5"
      :count="notificationsCount"
      :tooltip-text="notificationsTooltipText"
      size="m"
    />
  </div>
</template>

<script>
import KogNotification from 'sharedApp/components/base/tags/kog-notification.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'KogTab',
  components: {
    KogIcon,
    KogNotification,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    headingSize: {
      type: String,
      default: 'xxs',
    },
    isTabSelected: {
      type: Boolean,
      required: true,
    },
    hasTransparentBackground: {
      type: Boolean,
      required: true,
    },
    notificationsCount: {
      type: Number,
      default: 0,
    },
    notificationsTooltipText: {
      type: String,
      default: null,
    },
    iconClass: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
  computed: {
    showNotification() {
      return this.notificationsCount > 0;
    },
  },
  methods: {
    onClick() {
      this.$emit('click', this.name);
    },
  },
};
</script>

<style scoped>
.KogTab {
  position: relative;

  width: auto;

  font-weight: normal;
  text-align: center;

  background-color: var(--kog-tab-background-color);
  border-bottom: 3px solid var(--kog-tab-border-bottom-color);
}

.KogTab:hover {
  text-decoration: none;
  background-color: var(--kog-tab-hover-background-color);
}

.KogTab--transparent {
  background-color: var(--kog-tab-transparent-color);
}

.KogTab--active {
  font-weight: 700;
  color: var(--kog-tab-active-color);
  border-bottom: 3px solid var(--kog-tab-active-border-bottom-color);
}

.KogTab--inactive {
  color: var(--kog-tab-inactive-color);
}
</style>
