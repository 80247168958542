<template>
  <component
    :is="rowOrHeaderCell"
    ref="kogTableCellTextContainer"
    :class="{
      'KogTableCellText--paddLeft': isPaddedLeft,
      'text-nowrap': !isMultiLine,
      'KogTableCellText--truncate': !isMultiLine,
    }"
    class="text-regular padd-right-s"
  >
    <div
      v-if="isAbleToTruncate || isMultiLine"
      v-tooltip="{
        theme: 'kog-tooltip',
        content: tooltip,
        autoHide: false,
        boundary: 'window',
      }"
      class="KogTableCellText-container"
    >
      <div class="KogTableCellText--displayTableCell">
        <div
          class="flexContainer"
          :class="
            isSpanningMultipleLines ? 'flexContainer-alignStart' : 'flexContainer-alignCenter'
          "
        >
          <span
            ref="kogTableCellText"
            :class="{ 'KogTableCellText-container--truncate': !isMultiLine }"
          >
            <slot />
          </span>
          <kog-tag
            v-if="tagLabel"
            v-bind="tagProps"
            class="margin-left-s"
            :label="tagLabel"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      v-tooltip="{
        theme: 'kog-tooltip',
        content: tooltipText,
        autoHide: false,
        boundary: 'window',
      }"
      class="padd-top-xxs padd-bottom-xxs"
    >
      <slot />
    </div>
  </component>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { VTooltip } from 'floating-vue';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
const getSlotText = children =>
  children
    .map(node => (Array.isArray(node.children) ? getSlotText(node.children) : node.children))
    .join(' ');

export default {
  name: 'KogTableCellText',
  components: {
    KogTag,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    /**
     * Optional padding
     */
    isPaddedLeft: {
      type: Boolean,
      default: true,
    },
    /**
     * The text can be truncated if it goes beyond cell width.
     */
    isAbleToTruncate: {
      type: Boolean,
      default: false,
    },
    /**
     * Allow to display the text on multiple lines
     */
    isMultiLine: {
      type: Boolean,
      default: false,
    },
    /**
     * The cell can be used in a row header.
     * It will be a th instead of a td.
     */
    isHeaderCell: {
      type: Boolean,
      default: false,
    },
    /**
     * If set, then a tag will be added to the cell. <br>
     * <b>isAbleToTruncate</b> or <b>isMultiLine</b> should be true.
     */
    tagLabel: {
      type: String,
      default: '',
    },
    /**
     * Allow adding props to the tag.
     * Any prop set in KogTag can be passed.
     */
    tagProps: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Specifies content of optional tooltip that shows on checkbox hover.
     * No tooltip shown if this property is not provided.
     */
    tooltipText: {
      type: String,
      default: '',
    },
  },
  emits: ['on-multiple-line'],
  data() {
    return {
      isSpanningMultipleLines: false,
      isTruncated: false,
    };
  },
  computed: {
    rowOrHeaderCell() {
      return this.isHeaderCell ? 'th' : 'td';
    },
    tooltip() {
      if (this.tooltipText) {
        return this.tooltipText;
      }

      if (this.tooltipText === null) {
        return '';
      }

      return this.isTruncated ? getSlotText(this.$slots.default()) : '';
    },
  },
  created() {
    if (this.$props.isAbleToTruncate && this.$props.isMultiLine) {
      throw new Error('isAbleToTruncate and isMultiLine cannot be both true');
    }
  },
  mounted() {
    this.initObserver();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    initObserver() {
      this.observer = new ResizeObserver(this.onCellResize);
      this.observer.observe(this.$refs.kogTableCellTextContainer);
    },
    onCellResize() {
      if (!this.$refs.kogTableCellText) {
        return;
      }

      if (this.isMultiLine) {
        const { clientHeight } = this.$refs.kogTableCellText;
        const textLineHeight = parseInt(
          window
            .getComputedStyle(this.$refs.kogTableCellText, null)
            .getPropertyValue('line-height'),
          10,
        );
        const requestId = requestAnimationFrame(() => {
          if (clientHeight > textLineHeight) {
            if (!this.isSpanningMultipleLines) {
              this.isSpanningMultipleLines = true;
              this.$emit('on-multiple-line', true);
            }
          } else if (this.isSpanningMultipleLines) {
            this.isSpanningMultipleLines = false;
            this.$emit('on-multiple-line', false);
          }
          cancelAnimationFrame(requestId);
        });
      }

      if (this.isAbleToTruncate) {
        const { clientWidth, scrollWidth } = this.$refs.kogTableCellText;
        if (scrollWidth > clientWidth) {
          this.isTruncated = true;
        } else {
          this.isTruncated = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.KogTableCellText--truncate {
  padding-top: var(--space-xxs);
  /* when truncating, the text container will have `overflow: hidden`,
   so any focus outline will not be visible unless we provide some padding;
    As such we're splitting the default --space-xs padding provided by the
    `table :deep(td)` selector into two --space-xxs, one on the cell and one
    on KogTableCellText-container--truncate
  */
  padding-bottom: var(--space-xxs);
}

.KogTableCellText--paddLeft {
  padding-left: var(--space-xs);
}

.KogTableCellText-container {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.KogTableCellText--displayTableCell {
  display: table-cell;
}

.KogTableCellText-container--truncate {
  overflow: hidden;
  padding: var(--space-xxs);
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
