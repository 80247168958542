<template>
  <nav
    v-if="numberOfPages > 1"
    role="navigation"
    aria-label="pagination"
  >
    <div
      class="flexContainer flexContainer-center"
      :class="{
        'KogPaginator--newStyle': isNewStyle,
      }"
    >
      <kog-round-button
        v-if="isNextAndPreviousButtonsVisible"
        :class="{ visibilityHidden: !hasPrevious }"
        icon-class="fa-chevron-left"
        button-style="basic"
        :aria-hidden="!hasPrevious ? 'true' : null"
        :aria-label="paginatorButtonMessage('Previous')"
        :disabled="!hasPrevious"
        @click="goToPreviousPage()"
      />
      <kog-item-list
        role="list"
        class="flexContainer"
        :focusable-elements-selector="`.KogPaginator-pageNumber:not(.KogPaginator-splitter)`"
      >
        <button
          v-for="(page, index) in paginationRange"
          :key="index"
          v-tooltip="{
            theme: 'kog-tooltip',
            content: getPageTooltip(page),
            boundary: 'document.body',
            popperClass: 'text-center',
          }"
          :tabindex="getTabindex(page)"
          :class="[
            'KogButtonLegacy--noStyle',
            'KogPaginator-pageNumber',
            {
              'KogPaginator-currentPage': page === currentPage,
              'KogPaginator-active': isValidPage(page) && page !== currentPage,
              'KogPaginator-splitter': !isValidPage(page),
            },
          ]"
          :aria-disabled="page === currentPage ? 'true' : null"
          :aria-label="getPageAriaLabel(page)"
          @click="tryNavigateToPage($event, page)"
        >
          {{ getPageName(page) }}
        </button>
      </kog-item-list>
      <kog-round-button
        v-if="isNextAndPreviousButtonsVisible"
        :class="{ visibilityHidden: !hasNext }"
        icon-class="fa-chevron-right"
        button-style="basic"
        :aria-hidden="!hasNext ? 'true' : null"
        :aria-label="paginatorButtonMessage('Next')"
        :disabled="!hasNext"
        @click="goToNextPage()"
      />
    </div>
  </nav>
</template>

<script>
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import PaginationMixin from 'sharedApp/mixins/pagination-mixin.js';

export default {
  name: 'KogPaginator',
  components: {
    KogRoundButton,
    KogItemList,
  },
  mixins: [PaginationMixin],
  props: {
    /**
     * Content of the pages that is read out by screen readers
     * when clicking the "Next page" and "Previous page" buttons.
     * For example for "Assignments", the buttons would be read as
     * "Previous assignments page" and "Next assignments page".
     */
    paginationType: {
      type: String,
      default: '',
    },
    isNextAndPreviousButtonsVisible: {
      type: Boolean,
      default: true,
    },
    isNewStyle: {
      type: Boolean,
      default: false,
    },
    getPageContextByPageNumber: {
      type: Function,
      default: page => ({ page, tooltip: '' }),
    },
  },
  methods: {
    getPageAriaLabel(page) {
      const pageContext = this.getPageContextByPageNumber(page);
      if (page === this.currentPage) {
        return `You are on this page - ${pageContext.page}`;
      }
      return `Navigate to page ${pageContext.page}`;
    },
    getPageName(page) {
      if (this.isValidPage(page)) {
        const pageContext = this.getPageContextByPageNumber(page);
        return pageContext?.page;
      }
      return page;
    },
    getPageTooltip(page) {
      if (this.isValidPage(page)) {
        const pageContext = this.getPageContextByPageNumber(page);
        return pageContext?.tooltip;
      }
      return null;
    },
    paginatorButtonMessage(direction) {
      return `${direction} ${this.paginationType} page`;
    },
    getTabindex(page) {
      return page === this.currentPage ? 0 : -1;
    },
    tryNavigateToPage(event, page) {
      const pageElement = event.target;

      if (pageElement && pageElement.getAttribute('aria-disabled') === 'true') {
        return;
      }

      this.navigateToPage(page);
    },
  },
};
</script>

<style scoped>
.KogPaginator-pageNumber {
  display: inline-block;

  margin: 0 1px;
  padding: 0 var(--space-xxs);

  line-height: 32px;
  color: var(--kog-button-basic-label-default);

  border-radius: 4px;
}

.KogPaginator-active {
  cursor: pointer;
}

.KogPaginator-active:hover {
  background: var(--kog-list-item-hover-background);
}

.KogPaginator-splitter {
  cursor: default;
  color: var(--kog-button-basic-label-default);
}

.KogPaginator-currentPage {
  cursor: default;
  background: var(--kog-list-item-selected-background);
}

.KogPaginator--newStyle .KogPaginator-pageNumber {
  color: var(--kog-text-muted);
  background-color: var(--kogTransparent);
  border-radius: var(--space-xs);
}

.KogPaginator--newStyle .KogPaginator-active:hover,
.KogPaginator--newStyle .KogPaginator-active:active {
  color: var(--kogPlatformGray077);
  background: var(--kogPlatformGray096);
}

.KogPaginator--newStyle .KogPaginator-splitter {
  color: var(--kogPlatformGray077);
}

.KogPaginator--newStyle .KogPaginator-currentPage {
  color: var(--kogPlatformGray000);
  background: var(--kog-colors-aubergine-200);
}
</style>
