<template>
  <tr
    class="KogTableRow"
    :class="rowStyles"
    @mouseover="$emit('mouseover', $event)"
    @mouseleave="$emit('mouseleave', $event)"
    @focusin="$emit('focusin', $event)"
    @focusout="$emit('focusout', $event)"
  >
    <slot />
  </tr>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix
export default {
  name: 'KogTableRow',
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    isTopAligned: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isHeaderLike: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['focusin', 'focusout', 'mouseover', 'mouseleave'],
  computed: {
    rowStyles() {
      return {
        'KogTableRow--selected': this.isSelected,
        'KogTableRow--hoverable': !this.isDisabled,
        'vertical-align-top': this.isTopAligned,
        'KogTableRow--disabled': this.isDisabled,
        'KogTableRow--headerlike': this.isHeaderLike,
      };
    },
  },
};
</script>

<style scoped>
.KogTableRow {
  height: 48px;
  color: var(--kog-table-row-color);
  background: var(--kog-table-row-background);
  border-bottom: 1px solid var(--kog-table-row-border-color);
}

.KogTableRow--disabled {
  color: var(--kog-table-row-disabled-color);
}

.KogTableRow--hoverable:focus-within,
.KogTableRow--hoverable:hover {
  background-color: var(--kog-table-row-hover-background);
}

.KogTableRow--selected {
  background-color: var(--kog-table-row-selected-background);
}

.KogTableRow--selected:hover {
  background-color: var(--kog-table-row-selected-hover-background);
}

.vertical-align-top :deep(td),
.vertical-align-top :deep(th) {
  vertical-align: top;
}

.KogTableRow--headerlike :deep(td),
.KogTableRow--headerlike :deep(td.KogTable-bodyCell--sticky) {
  background-color: var(--kog-table-headerlike-background-color);
}
</style>
