<template>
  <kog-dropdown-v2
    ref="dropdownContainer"
    :items="items"
    :item-role="dropdownItemRole"
    :is-expanded="isDropdownOpen"
    :is-hosted-in-parent-container="isDropdownHostedInParentContainer"
    :placement="dropdownPlacement"
    :selected-item="selectedItem"
    :dropdown-item-type="dropdownItemType"
    @item-select="selectItem"
    @close="closeDropdown"
    @focusout="handleFocusOut"
  >
    <template #dropdownTrigger>
      <!--eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events-->
      <kog-round-button
        v-if="buttonType === 'round'"
        ref="toggleButton"
        :activated="isDropdownOpen"
        :aria-label="ariaLabel"
        aria-haspopup="true"
        :aria-expanded="isDropdownOpen ? 'true' : 'false'"
        :icon-class="iconClass"
        :icon-style="iconStyle"
        :icon-size="iconSize"
        :size="size"
        :button-style="buttonStyle"
        :disabled="disabled"
        @click="toggleDropdown"
        @mouseenter="handleMouseEnter"
      />
      <kog-button
        v-else
        ref="toggleButton"
        :aria-label="ariaLabel"
        :disabled="disabled"
        :has-dropdown="true"
        :has-icon="hasIcon"
        :icon-class="iconClass"
        :icon-style="iconStyle"
        :icon-size="iconSize"
        :is-dropdown-open="isDropdownOpen"
        :is-loading="isLoading"
        :label="label"
        :size="size"
        :button-style="buttonStyle"
        :tooltip="tooltip"
        :class="{
          'width-full': fullWidth,
        }"
        @click="toggleDropdown"
      />
    </template>
  </kog-dropdown-v2>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogDropdownV2 from 'sharedApp/components/dropdown/kog-dropdown-v2.vue';

export const VALID_DROPDOWN_ITEM_OPTIONS = ['text', 'radio', 'checkbox'];
export const VALID_BUTTON_STYLES = [
  'default',
  'primary',
  'accent',
  'danger',
  'basic',
  'secondary-basic',
  'secondary-outline',
  'inverted',
];
export const VALID_SIZES = ['medium', 'small', 'x-small'];
export const VALID_ICON_SIZES = ['m', 's'];
export const VALID_ICON_STYLES = ['solid', 'regular', 'light'];
export const VALID_BUTTON_TYPES = ['default', 'round'];

export default {
  name: 'KogDropdownButton',
  components: {
    KogButton,
    KogDropdownV2,
    KogRoundButton,
  },
  props: {
    /**
     * Optional button label to be read out by assistive screen reader technologies.
     */
    ariaLabel: {
      type: String,
      default: '',
    },
    /**
     * Specifies the type of button to be used.
     */
    buttonType: {
      type: String,
      default: 'default',
      validator: val => VALID_BUTTON_TYPES.includes(val),
    },
    /**
     * Controls if button is clickable or not.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Controls if an icon should be shown to the left of the button label.
     */
    hasIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Set to true if you want the popover to be rendered inside the parent container.
     * By default, it's rendered in the document body.
     */
    isDropdownHostedInParentContainer: {
      type: Boolean,
      default: false,
    },
    /**
     * font-awesome icon class to be shown on the button.
     * You also need to set `hasIcon: true` in order to see the icon.
     */
    iconClass: {
      type: String,
      default: '',
    },
    /**
     * If the icon should be solid, regular, or light (font-awesome icon variations).
     */
    iconStyle: {
      type: String,
      default: 'regular',
      validator: prop => VALID_ICON_STYLES.includes(prop),
    },
    iconSize: {
      type: String,
      default: 's',
      validator(value) {
        return !value || VALID_ICON_SIZES.includes(value);
      },
    },
    dropdownItemRole: {
      type: String,
      default: 'menuitem',
    },
    /**
     * Toggles if loading spinner should be shown in button.
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDropdownShownOnHover: {
      type: Boolean,
      default: true,
    },
    /**
     * The list of items and item-groups to show in the dropdown.<br>
     * Structure of an **item**:<br>
     * - `text: String`<br>
     * - `value: Object | String | Number`<br>
     * - `iconClass: String` (optional)<br>
     * - `disabled: Boolean` (optional)<br>
     * Structure of an **item-group**:<br>
     * - `text: String` - the group name<br>
     * - `options: Array<item>`
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * The text that should be displayed in the button.
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Controls padding, height, and minimum width of the button.
     */
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return !value || VALID_SIZES.includes(value);
      },
    },
    /**
     * Controls the styles of the button in terms of background,<br>
     * borders, text, and hover/focus/disabled states.
     * Make sure that the underlying button supports the `button-style` you choose,
     * since the supported values differ between `KogButton` and `KogRoundButton`.
     */
    buttonStyle: {
      type: String,
      default: '',
      validator(value) {
        return !value || VALID_BUTTON_STYLES.includes(value);
      },
    },
    /**
     * Sets the position of the dropdown.
     * Accepts any value that is valid for v-popover `placement`
     */
    dropdownPlacement: {
      type: String,
      default: 'bottom-start',
    },
    /**
     * Sets button to full width.
     */
    fullWidth: {
      type: Boolean,
      default: false,
    },
    /**
     * The item that's currently selected (when `KogDropdownButton` is used with `KogSelect`)
     */
    selectedItem: {
      type: Object,
      default: () => null,
    },
    dropdownItemType: {
      type: String,
      default: 'text',
      validator: prop => VALID_DROPDOWN_ITEM_OPTIONS.includes(prop),
    },
    closeDropdownOnSelect: {
      type: Boolean,
      default: true,
    },
    /**
     * Optional text to be shown in tooltip on button hover.<br>
     * Tooltip is centered above button.
     */
    tooltip: {
      type: String,
      default: '',
    },
  },
  emits: ['select', 'click'],
  data() {
    return {
      isDropdownOpen: false,
      previouslyFocusedElement: null,
    };
  },
  methods: {
    closeDropdown(shouldRestoreFocus = true) {
      if (this.isDropdownOpen) {
        this.isDropdownOpen = false;
        if (shouldRestoreFocus) {
          this.restoreFocusToElement();
        }
      }
    },
    selectItem(item) {
      /**
       * Emitted when an item in the dropdown is selected
       * */
      this.$emit('select', item);

      if (this.closeDropdownOnSelect) {
        this.closeDropdown();
      }
    },
    /**
     * This is used by parent components
     * for programatically showing the dropdown
     */
    showDropdown() {
      if (!this.isDropdownOpen) {
        this.storeFocusedElement();
        this.isDropdownOpen = true;
      }
    },
    toggleDropdown() {
      if (!this.isDropdownOpen) {
        this.storeFocusedElement();
      } else {
        this.restoreFocusToElement();
      }
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    storeFocusedElement() {
      this.previouslyFocusedElement = document.activeElement;
    },
    restoreFocusToElement() {
      const elementToFocus = this.previouslyFocusedElement || this.$refs.toggleButton.$refs.root;
      elementToFocus.focus();
    },
    handleMouseEnter() {
      if (!this.isDropdownShownOnHover || !this.isDropdownHostedInParentContainer) {
        return;
      }

      this.showDropdown();
    },
    handleFocusOut(focusEvent) {
      if (!this.isDropdownShownOnHover || !this.isDropdownHostedInParentContainer) {
        return;
      }

      const focusedElement = focusEvent.relatedTarget;
      const dropdownContainer = this.$refs.dropdownContainer.$refs.container;
      const isFocusedElementInsideContainer =
        dropdownContainer === focusedElement || dropdownContainer.contains(focusedElement);

      if (!isFocusedElementInsideContainer) {
        this.closeDropdown(false);
      }
    },
  },
};
</script>
