<template>
  <div
    ref="root"
    class="inline-block"
  >
    <span class="screenreader-only">
      {{ ariaLabel }}
    </span>
    <img
      v-show="showImage"
      alt=""
      :src="src"
      class="KogAvatar-rounded"
      :class="[
        {
          'KogAvatar-image-s': size === 's',
          'KogAvatar-image-m': size === 'm',
          'KogAvatar-image-l': size === 'l',
          'KogAvatar-image-xl': size === 'xl',
        },
      ]"
      aria-hidden="true"
      @load="onImageLoaded"
      @error="onImageLoadedError"
    />
    <div
      v-show="!showImage"
      aria-hidden="true"
      :class="[
        {
          'KogAvatar-initials--primary': theme === 'primary',
          'KogAvatar-initials--dark': theme === 'dark',
          'KogAvatar-initials--accent': theme === 'accent',
          'KogAvatar-initials-s': size === 's',
          'KogAvatar-initials-m': size === 'm',
          'KogAvatar-initials-l': size === 'l',
          'KogAvatar-initials-xl': size === 'xl',
        },
      ]"
      class="KogAvatar-initials KogAvatar-rounded"
    >
      {{ initials }}
    </div>
  </div>
</template>

<script>
import userInitials from 'sharedApp/libs/user-initials.js';

export default {
  name: 'KogAvatar',
  props: {
    /**
     * Optional message to be read out by assistive screen reader technologies.
     */
    ariaLabel: {
      type: String,
      default: null,
    },
    /**
     * The URL of the image. <br>
     * If null or empty or incorrect, the initials of the name will be used.
     */
    src: {
      type: String,
      default: null,
    },
    /**
     * Controls the size of the avatar.<br>
     * Can be small (24*24px), medium (32*32px), large (40*40px).
     */
    size: {
      type: String,
      default: 's',
      validator: value => ['s', 'm', 'l', 'xl'].includes(value),
    },
    /**
     * Controls the styles of the avatar in terms of background.<br>
     * The theme is applied only when initials are shown, not the image.
     */
    theme: {
      type: String,
      default: 'primary',
      validator: value => ['primary', 'accent', 'dark'].includes(value),
    },
    /**
     * The user full name, from which initials will be extracted.
     */
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isImageLoaded: false,
    };
  },
  computed: {
    showImage() {
      return this.src && this.isImageLoaded;
    },
    initials() {
      return userInitials(this.name);
    },
  },
  methods: {
    onImageLoaded() {
      this.isImageLoaded = true;
    },
    onImageLoadedError() {
      this.isImageLoaded = false;
    },
  },
};
</script>

<style scoped>
.KogAvatar-rounded {
  border-radius: 50%;
}

.KogAvatar-initials {
  text-align: center;
}

.KogAvatar-initials--primary {
  color: var(--kog-avatar-initials-primary-color);
  background: var(--kog-avatar-initials-primary-background-color);
}

.KogAvatar-initials--accent {
  color: var(--kog-avatar-initials-accent-color);
  background-color: var(--kog-avatar-initials-accent-background-color);
}

.KogAvatar-initials--dark {
  color: var(--kog-avatar-initials-dark-color);
  background-color: var(--kog-avatar-initials-dark-background-color);
}

.KogAvatar-initials--dark:hover {
  background-color: var(--kog-avatar-initials-dark-hover-background-color);
}

.KogAvatar-initials-s {
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
}

.KogAvatar-initials-m {
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
}

.KogAvatar-initials-l {
  width: 40px;
  height: 40px;
  font-size: 12px;
  line-height: 40px;
}

.KogAvatar-initials-xl {
  width: 80px;
  height: 80px;
  font-size: 32px;
  line-height: 80px;
}

.KogAvatar-image-s {
  width: 24px;
  height: 24px;
}

.KogAvatar-image-m {
  width: 32px;
  height: 32px;
}

.KogAvatar-image-l {
  width: 40px;
  height: 40px;
}

.KogAvatar-image-xl {
  width: 80px;
  height: 80px;
}
</style>
