<template>
  <div
    class="KogTableFooter flexContainer flexContainer-alignCenter padd-left-s padd-right-s"
    :class="{ 'flexContainer-spaceBetween': hasText }"
  >
    <div
      v-if="hasText"
      :class="{ 'padd-right-xs': hasPaginationSlot }"
      class="line-clamp-1"
    >
      {{ text }}
    </div>
    <div
      v-if="hasPaginationSlot"
      :class="{ 'margin-auto': !hasText }"
    >
      <slot name="pagination" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'KogTableFooter',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasText() {
      return this.text.length > 0;
    },
    hasPaginationSlot() {
      return Boolean(this.$slots.pagination);
    },
  },
};
</script>

<style scoped>
.KogTableFooter {
  height: 48px;
}
</style>
