<template>
  <th
    :class="{
      'padd-left-xs': isPaddedLeft,
      'KogTableCellHeader--leftGap': hasLeftGap,
    }"
    class="text-regular padd-right-s text-nowrap"
    scope="col"
  >
    <slot />
    <i
      v-if="isSortable"
      class="fas cursorPointer padd-xs margin-left-xxs"
      :class="`${iconClass}`"
      role="button"
      tabindex="0"
      @click.stop="sortColumn"
      @keydown.enter.stop="sortColumn"
    />
  </th>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import { SORT_ORDERS } from 'teachApp/utils/assignment-utils.js';

export default {
  name: 'KogTableCellHeader',
  props: {
    /**
     * Optional padding
     */
    isPaddedLeft: {
      type: Boolean,
      default: true,
    },
    /**
     * Optional callback function.
     * <br>
     * If defined, the column will display a sorting icon,
     * and clicking it will sort the column according to the function.
     */
    sortColumn: {
      type: Function,
      default: null,
    },
    /**
     * Optional prop to set a "not sorted" icon.
     */
    isSorted: {
      type: Boolean,
      default: false,
    },
    /**
     * Optional prop to set an initial sorting order (desc or asc)
     */
    sortOrder: {
      type: String,
      default: SORT_ORDERS.desc,
    },
    /**
     * Optional prop to show a gap to the left of the cell.
     */
    hasLeftGap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSortable() {
      return !!this.sortColumn;
    },
    iconClass() {
      if (!this.isSorted) {
        return 'fa-sort';
      }

      if (this.sortOrder === SORT_ORDERS.asc) {
        return 'fa-caret-up';
      }
      return 'fa-caret-down';
    },
  },
};
</script>

<style scoped>
.KogTableCellHeader--leftGap {
  border-left: var(--space-m) solid var(--kog-colors-white);
}
</style>
