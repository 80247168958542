<template>
  <span class="KogNotification-container">
    <div
      v-tooltip="{
        theme: 'kog-tooltip',
        content: tooltipText || '',
        boundary: 'document.body',
      }"
      :aria-label="tooltipText || ''"
      class="KogNotification"
      :class="componentClasses"
      :style="`top: ${offsetTop}px; left: ${offsetLeft}px`"
    >
      {{ showNumber ? count : '' }}
    </div>
  </span>
</template>

<script>
import { VTooltip } from 'floating-vue';

export default {
  name: 'KogNotification',
  directives: {
    tooltip: VTooltip,
  },
  props: {
    /**
     * Shows a number inside the notification except when `size: 's'`
     */
    count: {
      type: Number,
      default: null,
    },
    /**
     * Controls vertical positioning of the notification.
     * Can also be a negative number.
     */
    offsetTop: {
      type: Number,
      default: 0,
    },
    /**
     * Controls horizontal positioning of the notification.
     * Can also be a negative number.
     */
    offsetLeft: {
      type: Number,
      default: 0,
    },
    /**
     * Sets the size of the notification bubble.
     * Size `s` cannot display any text.
     */
    size: {
      type: String,
      default: 's',
      validator: value => ['s', 'm'].includes(value),
    },
    /**
     * Specify whether the notification should have a border.
     */
    hasBorder: {
      type: Boolean,
      default: false,
    },
    /**
     * Choose from the set of available usage styles.
     */
    type: {
      type: String,
      default: 'alert',
      validator: prop => ['alert', 'offline', 'online'].includes(prop),
    },
    /**
     * Optional text to display as a tooltip in case it's necessary
     * to provide the user with more details regarding the notification.
     * If provided, the value is also used as `aria-label`.
     */
    tooltipText: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClasses() {
      return [
        {
          'KogNotification--medium': this.size === 'm',
          'KogNotification--small': this.size === 's',
          'KogNotification--withBorder': this.hasBorder,
        },
        `KogNotification--${this.type}`,
      ];
    },
    showNumber() {
      return this.size === 'm' && this.count !== null;
    },
  },
};
</script>

<style scoped>
.KogNotification-container {
  position: relative;
}

.KogNotification {
  position: absolute;

  display: inline-block;

  font-size: 8px;
  color: var(--kog-notification-color);
  text-align: center;

  border-radius: 50%;
}

.KogNotification--alert {
  background-color: var(--kog-notification-alert-background-color);
}

.KogNotification--online {
  background-color: var(--kog-notification-online-background-color);
}

.KogNotification--offline {
  background-color: var(--kog-notification-offline-background-color);
}

.KogNotification--withBorder {
  border: 2px solid var(--kog-notification-border-color);
}

.KogNotification--medium {
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.KogNotification--medium.KogNotification--withBorder {
  width: 18px;
  height: 18px;
  line-height: 14px;
}

.KogNotification--small {
  width: 8px;
  height: 8px;
}

.KogNotification--small.KogNotification--withBorder {
  width: 10px;
  height: 10px;
}
</style>
