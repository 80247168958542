function userInitials(name) {
  const nameArray = name.trim().split(' ');
  const nameLength = nameArray.length;
  if (nameLength > 1) {
    return nameArray[0][0] + nameArray[nameLength - 1][0];
  }
  if (nameLength === 1) {
    return nameArray[0][0];
  }
  return '';
}

export default userInitials;
