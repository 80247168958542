const SPLITTER = '...';

export const getRangeFromOne = (numberOfPages, paginatorSize) => {
  const range = [];
  for (let i = 1; i <= paginatorSize && i <= numberOfPages; i += 1) {
    range.push(i);
  }
  return range;
};

export const getRangeFromLast = (numberOfPages, paginatorSize) => {
  const range = [];
  for (let i = numberOfPages; i > numberOfPages - paginatorSize && i >= 1; i -= 1) {
    range.push(i);
  }
  return range.reverse();
};

export const getRangeFromLimits = (initialNumber, lastNumber) => {
  const range = [];
  for (let i = initialNumber; i <= lastNumber; i += 1) {
    range.push(i);
  }
  return range;
};

export const addSplitters = (range, numberOfPages, paginatorSize) => {
  if (range.length < paginatorSize) {
    return range;
  }
  const updatedRange = range;
  if (updatedRange[0] !== 1) {
    updatedRange[0] = 1;
    updatedRange[1] = SPLITTER;
  }

  const lastPage = updatedRange.length - 1;
  const secondToLastPage = lastPage - 1;
  if (updatedRange[lastPage] !== numberOfPages) {
    updatedRange[lastPage] = numberOfPages;
    updatedRange[secondToLastPage] = SPLITTER;
  }

  return updatedRange;
};

export const getPaginationRange = (paginatorSize, currentPage, numberOfPages) => {
  const distanceToEdgeIndexes = Math.ceil((paginatorSize - 1) / 2);
  const initialNumber = currentPage - distanceToEdgeIndexes;
  const lastNumber = currentPage + distanceToEdgeIndexes - (paginatorSize % 2 === 0);

  let range;
  if (initialNumber < 1) {
    range = getRangeFromOne(numberOfPages, paginatorSize);
  } else if (lastNumber > numberOfPages) {
    range = getRangeFromLast(numberOfPages, paginatorSize);
  } else {
    range = getRangeFromLimits(initialNumber, lastNumber);
  }
  return addSplitters(range, numberOfPages, paginatorSize);
};

const PaginationMixin = {
  props: {
    /**
     * Controls which page number is currently selected
     */
    currentPage: {
      type: Number,
      default: 0,
    },
    /**
     * Controls how many pages exist in total
     */
    numberOfPages: {
      type: Number,
      default: 0,
    },
    /**
     * Controls how many pages can be displayed
     */
    paginatorSize: {
      type: Number,
      default: 7,
    },
  },
  computed: {
    hasNext() {
      return this.currentPage !== this.numberOfPages;
    },
    hasPrevious() {
      return this.currentPage !== 1;
    },
    paginationRange() {
      return getPaginationRange(this.paginatorSize, this.currentPage, this.numberOfPages);
    },
  },
  methods: {
    navigateToPage(page) {
      if (page === SPLITTER || page === this.currentPage) {
        return;
      }
      this.$emit('change-page', page);
    },
    goToNextPage() {
      if (this.currentPage !== this.numberOfPages) {
        this.$emit('change-page', this.currentPage + 1);
      }
    },
    goToPreviousPage() {
      if (this.currentPage !== 1) {
        this.$emit('change-page', this.currentPage - 1);
      }
    },
    isValidPage(page) {
      return page !== SPLITTER;
    },
  },
};

export default PaginationMixin;
